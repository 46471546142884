<template>
  <section class="mt-4">
    <div class="new-history">
      <h2 class="font-bold">Histórico de Callbacks</h2>
      <template v-if="historico.length > 0">
        <vs-table
          :data="historico"
          :hoverFlat="true"
          noDataText="Nenhuma histórico encontrado"
        >
          <template slot="thead">
            <vs-th>Identificação da consulta</vs-th>
            <vs-th>Status</vs-th>
            <vs-th class="text-left">Data da consulta</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].Id" class="text-left">
                {{ item.TransactionId || "-" }}
              </vs-td>

              <vs-td :data="item.Origin" class="text-left">
                {{ item.Status }}
              </vs-td>

              <vs-td :data="item" class="text-left">
                {{ item.TransactionDate | moment("DD/MM/YYYY HH:mm") }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p class="text-center">Nenhum registro encontrado!</p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "../../mixins/optionsStaticMixin";
import html2pdf from "html2pdf.js";
import { observable } from "vuedraggable";
import axiosInstance from "../../axios";
export default {
  name: "apolices-segurado",
  props: {
    dados: { type: Object, require: true },
    type: { type: String, require: true },
  },
  mixins: [optionsStaticMixin],
  mounted() {
    if (this.type == "BROKER") {
      this.listaBroker();
    } else {
       this.listaPolicyHolder();
    }
  },
  data() {
    return {
      historico: [
      ],
    };
  },

  methods: {
    listaBroker() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `api/Broker/GetInsuranceAssyncTransactionBroker?brokerId=${this.dados.BrokerId}`
          )
          .then((response) => {
            // if (response.data.Response)
            //   this.historico = response.data.Response;
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },
    listaPolicyHolder() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `api/PolicyHolder/GetInsuranceAssyncTransactionPolicyHolder?policyHolderId=${this.dados.Id}`
          )
          .then((response) => {
            if (response.data.Response)
              this.historico = response.data.Response;
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}
</style>

<style lang="scss">
.tree-view-item-key-with-chevron {
  color: red;
}

.tree-view-item-key-with-chevron[data-v-efc5bae2]::before {
  content: "\f067" !important;
  font-family: FontAwesome;
}

.tree-view-item-key-with-chevron.opened[data-v-efc5bae2]::before {
  content: "\f068" !important;
  transform: none;
  font-family: FontAwesome;
}
</style>
